
import { logAcessos } from "@/models/LogsModel";
import ApiService from "./ApiService";

/**
 * @description lista os itens reprovados da oficina digital
 */
 export const getIndex = async (codConcessionaria: number, dataInicial: string, dataFinal: string) : Promise<logAcessos> => {
    const { data } = await ApiService.get(`/logacessos/${codConcessionaria}/${dataInicial}/${dataFinal}`);
    return data;
 };

 export const graficoutilizacaoapp = async (codConcessionaria: number, dataInicial: string, dataFinal: string) => {
   const { data } = await ApiService.get(`/graficoutilizacaoapp/${codConcessionaria}/${dataInicial}/${dataFinal}`);
   return data;
};

export const graficoofertapacotes = async (codConcessionaria: number, dataInicial: string, dataFinal: string) => {
   const { data } = await ApiService.get(`/graficoofertapacotes/${codConcessionaria}/${dataInicial}/${dataFinal}`);
   return data;
};


export default { getIndex, graficoutilizacaoapp, graficoofertapacotes };